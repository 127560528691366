.table{
      overflow-y: auto;
      display: block;
      .product-image{
            width: 130px;
            height: 200px;
      }
      .carousel-image{
            width: 200px;
            height: 130px;
      }
}